import { BiomarkerPreviewItem } from '../../lib/validators'

interface BiomarkerPillProps {
  biomarker: BiomarkerPreviewItem
}

export function BiomarkerPill({ biomarker }: BiomarkerPillProps) {
  return (
    <div className="px-2 py-1 my-1 mr-1 inline-block border-2 border-dark-gray-lighterer/40 cursor-default text-md rounded-md text-dark-gray-light dark:text-white shadow-lg">
      {biomarker.name}
    </div>
  )
}
