import { Fragment, useCallback, useEffect, useState } from 'react'
import FullPageLoader from '../../layout/FullPageLoader'
import { useNavigate, useParams } from 'react-router-dom'
import {
  getPaymentIntentSecret,
  getProductWithPrice,
} from '../../../lib/payment'
import { ProductWithPrice, ProfileAddress } from '../../../lib/validators'
import RoundedSection from '../../layout/RoundedSection'
import BgtBloodDrop from '../../../../public/img/bgt-logo-full.png?url'
import { Errors, PaymentErrors } from '../../../lib/verifyErrors'
import TextInputField, { onChangeInterface } from '../../form/TextInputField'
import PasswordStength, { OnChangeProps } from '../../form/PasswordStrength'
import SimpleSelectField from '../../form/SimpleSelectField'
import {
  dateInThePast,
  formatMoney,
  isEmailValid,
  setDocumentTitle,
} from '../../../lib/utils'
import PhoneNumberInput from '../../form/PhoneNumberInput'
import Checkbox from '../../form/Checkbox'
import ThreeDots from 'react-loading-icons/dist/esm/components/three-dots'
import {
  Elements,
  PaymentElement,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js'
import { stripePromise } from '../../../lib/billing'
import { useUser } from '../../../lib/store'
import { UserImage } from '../../layout/UserImage'
import { createUserWithProfile } from '../../../lib/user'
import { GenderTypes } from '../../../lib/types'
import toast from 'react-hot-toast'
import { FaCircleCheck } from 'react-icons/fa6'
import { Button } from '../../form/button/Button'
import AuthStore from '../../../lib/AuthStore'
import { getSizedImage, OrgImageTypes } from '../../../lib/image'
import { TestTurnaroundTime } from '../../ui/TestTurnaroundTime'

interface FormElements extends HTMLFormControlsCollection {
  firstName: HTMLInputElement
  lastName: HTMLInputElement
  gender: HTMLInputElement
  email: HTMLInputElement
  dob: HTMLInputElement
  terms: HTMLInputElement
  edm: HTMLInputElement
  password: HTMLInputElement
  confirmPassword: HTMLInputElement
}
interface CreateAccountPurchaseFormElement extends HTMLFormElement {
  readonly elements: FormElements
}
interface CreateAccountPurchasePayload {
  firstName?: string
  lastName?: string
  email?: string
  dob?: string
  gender?: 'male' | 'female'
  terms?: boolean
  password?: string
  edm?: boolean
  phone?: string
  address?: ProfileAddress
}
const requiredFields: Record<string, boolean> = {
  firstName: true,
  lastName: false,
  email: true,
  dob: true,
  gender: true,
  terms: true,
  password: true,
  edm: false,
  phone: true,
  address: true,
}
export function AdvancedStandalonePurchasePage() {
  const params = useParams()
  const navigate = useNavigate()
  const [product, setProduct] = useState<ProductWithPrice>()

  useEffect(() => {
    if (!params.id) {
      navigate('/')
      return
    } else {
      getProductWithPrice(params.id)
        .then((d) => {
          setProduct(d)
          setDocumentTitle(`${d.name} by ${d.organisation.name}`)
        })
        .catch((e) => {
          console.error('Product not found.', e)
        })
    }
  }, [params.id, navigate])
  return (
    <Fragment>
      {product && (
        <Elements
          stripe={stripePromise}
          options={{
            amount: product.price,
            mode: 'payment',
            currency: 'aud',
            setup_future_usage: 'on_session',
          }}
        >
          <AdvancedStandalonePurchase
            product={product}
          ></AdvancedStandalonePurchase>
        </Elements>
      )}
    </Fragment>
  )
}

interface AdvancedStandalonePurchaseProps {
  product?: ProductWithPrice
}

export function AdvancedStandalonePurchase({
  product,
}: AdvancedStandalonePurchaseProps) {
  const [loading, setLoading] = useState(false)
  const [isFormReady, setFormReady] = useState(false)
  const [saving, setSaving] = useState(false)
  const [formErrorState, setFormErrorState] = useState('')
  const [formData, setFormData] = useState<CreateAccountPurchasePayload>({
    edm: true,
  })
  const [purchaseAsLoggedInUser, setPurchaseAsLoggedInUser] = useState(false)
  const [showPayment, setShowPayment] = useState(false)
  const [userUuid, setUserUuid] = useState('')
  const [userEmail, setUserEmail] = useState('')
  const [purchaseComplete, setPurchaseComplete] = useState(false)

  const stripe = useStripe()
  const elements = useElements()
  const user = useUser()

  useEffect(() => {
    if (user) {
      setPurchaseAsLoggedInUser(true)
      setShowPayment(true)
      setUserEmail(user.email)
      setUserUuid(user.uuid)
    }
  }, [user])

  const getAddress = useCallback((): ProfileAddress | undefined => {
    if (formData.address) {
      return formData.address
    }
    return
  }, [formData.address])

  const getPrettyAddress = useCallback(
    (add?: ProfileAddress): string => {
      const address = add ? add : getAddress()
      if (!address) {
        return ''
      }
      const line2 = address.addressLine2 || ''
      return `${address.addressLine1} ${line2}, ${address.city} ${
        address.state
      } ${address.postCode || ''}`
    },
    [getAddress]
  )

  const handleElementChange = (e: onChangeInterface) => {
    setFormData((prev: CreateAccountPurchasePayload) => ({
      ...prev,
      [e.name]: e.value,
    }))
  }

  const handlePasswordChange = (props: OnChangeProps) => {
    setFormData((prev: CreateAccountPurchasePayload) => ({
      ...prev,
      [props.name]: props.value,
    }))
  }

  const handleSubmit = async (
    event: React.FormEvent<CreateAccountPurchaseFormElement>
  ) => {
    event.preventDefault()

    if (elements == null) {
      return
    }
    if (!product) {
      throw new Error('Missing required data, this shouldnt happen.')
    }
    if (
      !purchaseAsLoggedInUser &&
      (!formData.address ||
        !formData.firstName ||
        !formData.email ||
        !formData.dob ||
        !formData.password)
    ) {
      throw new Error('Missing required data, this shouldnt happen.')
      return
    }

    // // Trigger form validation and wallet collection
    const { error: submitError } = await elements.submit()
    if (submitError) {
      // Show error to your customer
      console.log('an error happened', submitError)
      // setFormErrorState(PaymentErrors.StripeFailure)
      return
    }
    setFormErrorState('')
    // we need to create a user here so we have a userUuid.

    if (!userUuid) {
      setFormErrorState(PaymentErrors.MissingUser)
      return
    }
    const toastId = toast.loading(`Purchasing ${product.name}`, {
      duration: 3000,
    })
    setSaving(true)
    // get the payment intent
    const clientSecret = await getPaymentIntentSecret({
      amount: product.price,
      currency: 'aud',
      productName: product.name,
      userUuid: userUuid,
      email: userEmail!,
      stripeProductId: product.stripeProductId,
      orgName: product.organisation.name,
      orgUuid: product.organisation.uuid,
      productId: product.id,
      origin: 'ADVANCED_BUY', // NEVER CHANGE THIS - its how we process the stripe webhook
    })

    const intentResponse = await stripe?.confirmPayment({
      clientSecret: clientSecret.clientSecret,
      redirect: 'if_required',
      confirmParams: {
        return_url: 'https://app.eirly.dev/foobar',
      },
      elements: elements,
    })
    if (intentResponse?.error) {
      setFormErrorState(PaymentErrors.PurchaseFailed)
      toast.error(
        `Purchase failed\n\nReason: ${intentResponse.error.message}`,
        {
          id: toastId,
        }
      )
      return
    }
    toast.success('Purchase successful', {
      id: toastId,
    })
    setSaving(false)
    setPurchaseComplete(true)
  }

  const termsValid = useCallback((): boolean => {
    if (requiredFields.terms === true && formData.terms === true) {
      return true
    }
    if (requiredFields.terms === false) {
      return true
    }
    if (requiredFields.terms === undefined) {
      return true
    }
    return false
  }, [formData.terms])
  const dobValid = useCallback((): boolean => {
    if (requiredFields.dob === true && formData.dob) {
      return true
    }
    if (requiredFields.dob === undefined) {
      return true
    }
    return false
  }, [formData.dob])
  const genderValid = useCallback((): boolean => {
    if (requiredFields.gender === true && formData.gender) {
      return true
    }
    if (requiredFields.gender === undefined) {
      return true
    }
    return false
  }, [formData.gender])
  const phoneNumberValid = useCallback((): boolean => {
    if (requiredFields.phone === true && formData.phone) {
      return true
    }
    if (requiredFields.phone === undefined) {
      return true
    }
    return false
  }, [formData.phone])
  const addressValid = useCallback((): boolean => {
    if (requiredFields.address === true && formData.address) {
      return true
    }
    if (requiredFields.address === undefined) {
      return true
    }
    return false
  }, [formData.address])
  const isPasswordValid = useCallback((): boolean => {
    return formData.password !== undefined && formData.password !== ''
  }, [formData.password])
  const isNameValid = useCallback((): boolean => {
    return formData.firstName !== undefined && formData.firstName !== ''
  }, [formData.firstName])
  const emailValid = useCallback((): boolean => {
    return (
      formData.email !== undefined &&
      formData.email !== '' &&
      isEmailValid(formData.email)
    )
  }, [formData.email])

  useEffect(() => {
    let isValid = false
    // we need to make sure the payment stuff is ready.

    if (purchaseAsLoggedInUser) {
      setFormReady(true)
      return
    }

    if (
      emailValid() &&
      isNameValid() &&
      termsValid() &&
      dobValid() &&
      genderValid() &&
      isPasswordValid() &&
      phoneNumberValid() &&
      addressValid()
    ) {
      isValid = true
    }

    setFormReady(isValid)
  }, [
    formData,
    isPasswordValid,
    termsValid,
    dobValid,
    genderValid,
    addressValid,
    phoneNumberValid,
    emailValid,
    isNameValid,
    purchaseAsLoggedInUser,
  ])
  useEffect(() => {
    if (purchaseAsLoggedInUser === false) {
      setFormReady(false)
    }
  }, [purchaseAsLoggedInUser])

  const getPriceIncGST = (price: number): number => {
    return price * 1.1
  }

  const createAccount = async () => {
    if (!product) {
      setFormErrorState(PaymentErrors.MissingProduct)
      return
    }
    if (
      purchaseAsLoggedInUser === false &&
      formData.address &&
      formData.email &&
      formData.firstName &&
      formData.password
    ) {
      // setLoading(true)
      setSaving(true)
      const newUser = await createUserWithProfile({
        address: {
          addressLine1: formData.address.addressLine1,
          addressLine2: formData.address.addressLine2,
          city: formData.address.city,
          country: formData.address.country,
          state: formData.address.state,
        },
        email: formData.email,
        firstName: formData.firstName,
        lastName: formData.lastName || '',
        dob: new Date(Date.parse(formData.dob!)),
        edm: formData.edm,
        gender: formData.gender as GenderTypes,
        phone: formData.phone,
        password: formData.password,
        acceptedLatestTerms: true,
        userSetPassword: true,
        organisationUuid: product.organisation.uuid,
      })
      setUserUuid(newUser.userUuid)
      setUserEmail(formData.email)
      // setLoading(false)
      setSaving(false)
    }

    setShowPayment(true)
    setTimeout(() => {
      const element = document.getElementById('payment-section')
      element?.scrollIntoView({ behavior: 'smooth' })
    }, 300)
  }

  return (
    <Fragment>
      {loading && <FullPageLoader></FullPageLoader>}
      {!loading && product && (
        <div className="min-h-full flex items-center justify-center py-6 px-4 sm:px-6 lg:px-8">
          <div className="max-w-md w-full space-y-8">
            <div className="flex justify-center flex-row align-middle items-center">
              <div
                className={` ${!product.organisation.theme?.logo ? '' : 'w-5/12 text-right justify-end flex'}`}
              >
                <img
                  alt=""
                  className="rounded-md max-w-32 max-h-16"
                  src={BgtBloodDrop}
                />
              </div>
              {product.organisation.theme?.logo && (
                <Fragment>
                  <div className="w-10 text-center text-xl font-bold">+</div>
                  <div className="w-5/12">
                    <img
                      alt=""
                      className="rounded-md max-w-32 max-h-16"
                      src={getSizedImage(
                        OrgImageTypes.Preview,
                        product.organisation.theme?.logo
                      )}
                    />
                  </div>
                </Fragment>
              )}
            </div>
            <RoundedSection size="lg" className="sm:!p-4">
              <h1 className="text-2xl font-bold leading-9 tracking-tight text-gray-900">
                {product.name}
                <span className="font-normal text-base block -mt-1 mb-2">
                  by {product.organisation.name}
                </span>
              </h1>
              <div className="text-dark-gray-light font-bold text-xl">
                {formatMoney(getPriceIncGST(product.price) / 100, 'aud')}{' '}
                <span className="font-normal text-sm">inc. GST</span>
              </div>
              {product.description && (
                <div className="mt-2">{product.description}</div>
              )}
              {product && product.turnAroundTimeAverage && (
                <div className="mt-4">
                  <TestTurnaroundTime
                    turnaroundTimes={product.turnAroundTimeAverage}
                    showMissingText={false}
                  ></TestTurnaroundTime>
                </div>
              )}
            </RoundedSection>
            {purchaseComplete && (
              <RoundedSection size="lg" className="sm:!p-4">
                <div className="p-10 text-center flex flex-col  content-center ">
                  <div className="justify-center mr-auto ml-auto pb-5">
                    <FaCircleCheck size={70} color="#00edc0" />
                  </div>
                  <h2 className="text-2xl font-bold">Purchase successful</h2>
                  <div className="mt-2">
                    Please check your inbox for an email from Bloody Good Tests
                    which will contain your referral.
                  </div>
                  <div className="mt-2">
                    <Button onClick={() => window.location.reload()}>
                      Buy another test
                    </Button>
                  </div>
                </div>
              </RoundedSection>
            )}

            {!purchaseComplete && (
              <Fragment>
                <form
                  className="space-y-6"
                  method="POST"
                  onSubmit={handleSubmit}
                >
                  {formErrorState && (
                    <RoundedSection size="lg" className="sm:!p-4">
                      <div
                        className="bg-bgt-primary/10 border-t-4 border-bgt-primary rounded-b text-teal-900 px-4 py-3 shadow-md"
                        role="alert"
                      >
                        <div className="flex">
                          <div className="py-1">
                            <svg
                              className="fill-current h-6 w-6 text-bgt-primary mr-4"
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 20 20"
                            >
                              <path d="M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm12.73-1.41A8 8 0 1 0 4.34 4.34a8 8 0 0 0 11.32 11.32zM9 11V9h2v6H9v-4zm0-6h2v2H9V5z" />
                            </svg>
                          </div>
                          <div>
                            <p className="font-bold">Error</p>
                            {formErrorState === Errors.UnknownError && (
                              <p className="text-sm">
                                An unknown error occured.
                              </p>
                            )}
                            {formErrorState === Errors.ServerError && (
                              <p className="text-sm">
                                Server returned an error, please try again later
                              </p>
                            )}
                            {formErrorState === PaymentErrors.MissingUser && (
                              <p className="text-sm">
                                Unable to continue, the user failed to create.
                              </p>
                            )}
                            {formErrorState ===
                              PaymentErrors.PurchaseFailed && (
                              <p className="text-sm">
                                Unfortunately the purchase failed.
                              </p>
                            )}
                            {formErrorState ===
                              PaymentErrors.MissingProduct && (
                              <p className="text-sm">
                                Unable to find product matching uuid
                              </p>
                            )}
                            {formErrorState === PaymentErrors.StripeFailure && (
                              <p className="text-sm">
                                Something seems to have gone wrong with our
                                payment provider, please try again later.
                              </p>
                            )}
                            {formErrorState === PaymentErrors.UnknownError && (
                              <p className="text-sm">
                                Something seems to have gone wrong with our
                                payment provider, please try again later.
                              </p>
                            )}
                          </div>
                        </div>
                      </div>
                    </RoundedSection>
                  )}
                  {!user && (
                    <RoundedSection size="lg">
                      <div className="mt-2 ">
                        <div>
                          Already have a BGT account?{' '}
                          <a
                            className="text-sm text-blood hover:underline cursor-pointer"
                            onClick={() =>
                              AuthStore.sendUserToLoginPage(
                                true,
                                window.location.toString()
                              )
                            }
                          >
                            Click here to log in
                          </a>
                        </div>
                      </div>
                    </RoundedSection>
                  )}
                  {(!user || purchaseAsLoggedInUser === false) && (
                    <Fragment>
                      <RoundedSection size="lg" className="sm:!p-4">
                        <div>
                          <h3 className="text-xl font-bold leading-9 tracking-tight text-gray-900">
                            Personal Information
                            {user && (
                              <span
                                className="text-sm text-blood hover:underline cursor-pointer float-right font-normal"
                                onClick={() => {
                                  setPurchaseAsLoggedInUser(true)
                                  setShowPayment(true)
                                }}
                              >
                                Purchase for myself
                              </span>
                            )}
                          </h3>
                          <div className="text-md">
                            Please fill in your personal information to ensure
                            we can issue your referral and ensure the correct
                            reporting of your results. This form will also
                            create your a Bloody Good Tests account if you do
                            not already have one.
                          </div>
                          <div>
                            <TextInputField
                              id="firstName"
                              label="First Name"
                              name="firstName"
                              type="text"
                              value={formData.firstName}
                              required={true}
                              disabled={saving || loading}
                              handleChange={handleElementChange}
                            ></TextInputField>
                          </div>
                          <div>
                            <TextInputField
                              id="lastName"
                              label="Last Name"
                              name="lastName"
                              value={formData.lastName}
                              type="text"
                              disabled={saving || loading}
                              handleChange={handleElementChange}
                            ></TextInputField>
                          </div>
                          <div>
                            <TextInputField
                              id="email"
                              label="Email"
                              name="email"
                              type="email"
                              value={formData.email}
                              required={true}
                              disabled={saving || loading}
                              handleChange={handleElementChange}
                            ></TextInputField>
                          </div>

                          <div>
                            <SimpleSelectField
                              handleChange={handleElementChange}
                              label="Sex assigned at birth"
                              name="gender"
                              value={formData['gender']}
                              required={true}
                              firstItemText="-- Please select an option --"
                              disabled={saving || loading}
                              options={[
                                {
                                  label: 'Male',
                                  value: 'male',
                                },
                                {
                                  label: 'Female',
                                  value: 'female',
                                },
                              ]}
                            ></SimpleSelectField>
                          </div>
                          <div>
                            <TextInputField
                              id="date"
                              label="Date of Birth"
                              name="dob"
                              type="date"
                              required={true}
                              min={dateInThePast(130)}
                              max={dateInThePast(18)}
                              placeholder="Select your date of birth, you must be over 18 years of age."
                              disabled={saving || loading}
                              handleChange={handleElementChange}
                            ></TextInputField>
                          </div>
                          <div className="mt-2">
                            <PhoneNumberInput
                              id="phone"
                              label="Phone number"
                              name="phone"
                              type="tel"
                              required={true}
                              disabled={saving || loading}
                              handleChange={handleElementChange}
                            ></PhoneNumberInput>
                          </div>
                          <div className="mt-2">
                            <TextInputField
                              id="address"
                              label="Street Address"
                              name="address"
                              type="address"
                              required={true}
                              disabled={saving || loading}
                              handleChange={handleElementChange}
                              value={getPrettyAddress() || ''}
                            ></TextInputField>
                          </div>
                          <div>
                            <PasswordStength
                              id="password"
                              name="password"
                              label="Password"
                              onChange={handlePasswordChange}
                              segments={5}
                              required={true}
                              disabled={saving || loading}
                              segmentColours={[
                                'bg-bgt-primary',
                                'bg-bgt-primary/80',
                                'bg-bgt-primary/60',
                                'bg-jade/60',
                                'bg-jade',
                              ]}
                            ></PasswordStength>
                          </div>
                        </div>
                      </RoundedSection>
                      <RoundedSection size="lg" className="sm:!p-4">
                        <div>
                          <div>
                            <h3 className="text-xl font-bold leading-9 tracking-tight text-gray-900">
                              Consent
                            </h3>
                            <Fragment>
                              <Checkbox
                                id="terms"
                                name="terms"
                                type="checkbox"
                                disabled={saving || loading}
                                onChange={handleElementChange}
                                checked={formData.terms}
                                checkboxLabel={
                                  <Fragment>
                                    I consent to Bloody Good Tests (BGT)
                                    handling lab work. I understand that BGT
                                    does not provide medical advice. I have read
                                    and agree to the{' '}
                                    <a
                                      className="text-bgt-primary/80 hover:underline hover:text-bgt-primary"
                                      href="https://www.bloodygoodtests.com.au/terms-of-use"
                                      target="_terms"
                                    >
                                      Terms of Use
                                    </a>
                                  </Fragment>
                                }
                              ></Checkbox>
                              <br />
                            </Fragment>
                            <Checkbox
                              id="edm"
                              name="edm"
                              type="checkbox"
                              disabled={saving || loading}
                              checked={formData.edm}
                              onChange={handleElementChange}
                              checkboxLabel={
                                <Fragment>
                                  I wish to subscribe to marketing material
                                  about Bloody Good Tests
                                </Fragment>
                              }
                            ></Checkbox>
                          </div>
                        </div>
                        {!showPayment && (
                          <div className="mt-4">
                            <button
                              type="button"
                              className="flex w-full justify-center rounded-md bg-bgt-primary/90 px-3 py-1.5 text-sm font-semibold leading-6 text-off-white shadow-sm hover:bg-bgt-primary focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-bgt-primary disabled:bg-gray-dark disabled:hover:bg-gray-dark"
                              disabled={!isFormReady || saving}
                              onClick={createAccount}
                            >
                              {!saving && <span>Continue to Payment</span>}
                              {saving && <ThreeDots height={20} />}
                            </button>
                          </div>
                        )}
                      </RoundedSection>
                    </Fragment>
                  )}

                  {user && purchaseAsLoggedInUser && (
                    <RoundedSection size="lg">
                      <div className="text-sm text-dark-gray-light">
                        Currently logged in as:
                      </div>
                      <div className="flex mt-2 ">
                        <div className="mr-4 align-middle mt-2">
                          <UserImage size="sm" image={user?.image}></UserImage>
                        </div>
                        <div>
                          {[user.firstName, user.lastName].join(' ')}
                          <div className="text-dark-gray-lighterer -mt-1">
                            {user.email}
                          </div>
                          <span
                            className="text-sm text-blood hover:underline cursor-pointer"
                            onClick={() => {
                              setPurchaseAsLoggedInUser(false)
                              setShowPayment(false)
                            }}
                          >
                            Purchase for someone else
                          </span>{' '}
                          |{' '}
                          <span
                            className="text-sm text-blood hover:underline cursor-pointer"
                            onClick={() => {
                              AuthStore.beginLogoutProcess(true)
                              setPurchaseAsLoggedInUser(false)
                              setShowPayment(false)
                            }}
                          >
                            logout
                          </span>
                        </div>
                      </div>
                    </RoundedSection>
                  )}

                  <RoundedSection
                    size="lg"
                    id="payment-section"
                    className={`${showPayment ? 'block' : 'hidden'}`}
                  >
                    <div>
                      <div>
                        <h3 className="mt-6  text-xl font-bold leading-9 tracking-tight text-gray-900">
                          Payment details
                        </h3>
                        <PaymentElement onReady={() => setLoading(false)} />
                      </div>
                      <div className="mt-4">
                        <button
                          type="submit"
                          className="flex w-full justify-center rounded-md bg-bgt-primary/90 px-3 py-1.5 text-sm font-semibold leading-6 text-off-white shadow-sm hover:bg-bgt-primary focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-bgt-primary disabled:bg-gray-dark disabled:hover:bg-gray-dark"
                          disabled={!isFormReady || saving}
                        >
                          {saving && <ThreeDots height={20} />}
                          {!saving && (
                            <span>
                              Pay{' '}
                              {formatMoney(
                                getPriceIncGST(product.price) / 100,
                                'aud'
                              )}
                            </span>
                          )}
                        </button>
                      </div>
                    </div>
                  </RoundedSection>
                </form>
              </Fragment>
            )}
          </div>
        </div>
      )}
    </Fragment>
  )
}
