import { authFetch } from '../providers/AuthProvider'
import {
  FileListSchema,
  HistoricalBiomarkerResult,
  HistoricalBiomarkerResults,
  HistoricalBiomarkerResultsSchema,
  Result,
  ResultReferral,
  ResultReferralSchema,
  ResultSchemaApiSchema,
  File,
} from './validators'

export const defaultDisclaimer = `<i>Please note:</i> No data has been altered or changed in this report, all results are reported as provided by the Pathology provider. The test results and any accompanying comments or interpretations we provide are solely for informational purposes. While they can offer insights to help you understand your health, they are not designed to serve as a clinical diagnosis.<br /><br />
Our service is not an alternative to thorough medical investigation and professional advice, as we do not offer clinical or diagnostic services related to the tests.<br /><br />
The comments we provide are based on the information available to us at the time, which may not be comprehensive enough to fully understand your overall health or specific medical conditions. If you have concerns about your health or your test results, we strongly advise discussing them with your GP or a doctor.`

export const defaultConclusion =
  'We hope that this report was insightful and helps you along your health journey! We know, first hand, how important it is to track various aspects of our health, and blood results are just the tip of the iceberg.<br /><br />We have provided help in interpreting your results, however, as things go, remember that blood test results should be considered in light of your overall health and interpretation should be done in combination with other measures you may have available to you.<br /><br />If you have any abnormal results or are worried about any of your results, we recommend you speak to your doctor or other medical professional. We look forward to having you back at Bloody Good Tests for any future blood test needs!'

export const defaultThankYou = `A massive “Bloody Good” thank you for trusting us to check up on your health! We hope that these results provide you with valuable insights about your well-being. To help you better understand your results, we've provided a brief overview and interpretation that you can use in combination with the original pathology results.`

type resultIncludes = 'files'

export interface GetResultsDataForProfileAndUuidProps {
  resultUuid: string
  profileUuid?: string
  organisationUuid?: string
  include?: resultIncludes[]
}

export async function getResultsDataForProfileAndUuid({
  resultUuid,
  profileUuid,
  include,
  organisationUuid,
}: GetResultsDataForProfileAndUuidProps): Promise<ResultReferral> {
  // setTimeout(async () => {
  const queryName = 'result'
  const gqlQuery = `${queryName}(uuid: "${resultUuid}" ${
    profileUuid ? `profileUuid: "${profileUuid}"` : ''
  } ${organisationUuid ? `organisationUuid: "${organisationUuid}"` : ''})`

  const result = await authFetch(`${import.meta.env.VITE_API_URL}/graphql`, {
    method: 'POST',
    headers: {
      'content-type': 'application/json',
    },
    data: JSON.stringify({
      query: `{
          ${gqlQuery} {
            uuid
            name
            createdAt
            notes
            status
            pathologyProviderId
            aiSummaryConsentUuid
            aiSummary
            profileUuid
            patientName
            resultDate
            ${
              include && include.indexOf('files') !== -1
                ? `files {
              filename
              filesize
            }`
                : ''
            }
            biomarkers {
              ... on NumericBiomarkerResult {
                id
                name
                commonName
                description
                prefix
                suffix
                unit
                category {
                  name
                  id
                  description
                }
                valueType
                refIntervalHigh
                refIntervalLow
                refIntervalHighNotes
                refIntervalMidNotes
                refIntervalLowNotes
                numericValue
                subValueType
              }
              ... on StringBiomarkerResult {
                id
                name
                commonName
                description
                prefix
                suffix
                unit
                category {
                  name
                  id
                  description
                }
                valueType
                stringValue
              }
            }
            referral {
              uuid
              status
              createdAt
              testName
              reference
              referralType
              product {
                id
                name
                description
              }
            }
            optimalRanges {
              uuid
              organisationUuid
              name
              totalBiomarkers
              createdAt
              updatedAt
              criteria {
                gender
              }
              specs {
                biomarkerId
                unit
                ranges {
                  low
                  high
                }
              }
            }
            resultProfile {
              gender
              age
              dob
            }
          }}`,
    }),
  })

  const data = result.data
  const parsedData = ResultReferralSchema.parse(data.data[queryName])
  return parsedData
}

export interface GetHistoricalResultsForBiomarkersProps {
  profileUuid?: string
  organisationUuid?: string
  biomarkers: string[]
  forProfile?: string
}

export async function getHistoricalResultsForBiomarkers({
  profileUuid,
  organisationUuid,
  biomarkers,
  forProfile,
}: GetHistoricalResultsForBiomarkersProps): Promise<HistoricalBiomarkerResults> {
  const queryName = 'getResultHistoryForBiomarkers'

  const result = await authFetch(`${import.meta.env.VITE_API_URL}/graphql`, {
    method: 'POST',
    headers: {
      'content-type': 'application/json',
    },
    data: JSON.stringify({
      query: `query name($organisationUuid: UUID $profileUuid: UUID $biomarkerIds: [String!] $forProfileUuid: UUID) {
        ${queryName}(organisationUuid: $organisationUuid profileUuid: $profileUuid biomarkerIds: $biomarkerIds forProfileUuid: $forProfileUuid) {
          startDate,
          endDate
          biomarkers {
            ... on IndividualNumericBiomarkerResult {
              id
              provider
              date
              unit
              valueType
              subValueType
              numericValue
              refIntervalLow
              refIntervalHigh
            }
            ... on IndividualStringBiomarkerResult {
              id
              provider
              date
              unit
              valueType
              stringValue
            }
          }
        }
      }`,
      variables: {
        biomarkerIds: biomarkers,
        organisationUuid: organisationUuid,
        profileUuid: profileUuid,
        forProfileUuid: forProfile,
      },
    }),
  })

  const data = result.data
  const parsedData = HistoricalBiomarkerResultsSchema.parse(
    data.data[queryName]
  )
  return parsedData
}

export async function getResultsData(
  profileUuid: string,
  organisationUuid?: string
): Promise<Result[]> {
  // setTimeout(async () => {

  // console.log("we have a profile", profile);
  const result = await authFetch(`${import.meta.env.VITE_API_URL}/graphql`, {
    method: 'POST',
    headers: {
      'content-type': 'application/json',
    },
    data: JSON.stringify({
      query: `{
          results(profileUuid: "${profileUuid}" ${organisationUuid ? `organisationUuid: "${organisationUuid}"` : ''}) {
            uuid
            name
            createdAt
            referralUuid
            patientName
            status
            resultDate
            biomarkerSummary {
              critical
              abnormal
              normal
              total
            }
          }}`,
    }),
  })
  const data = result.data
  const parsedData = ResultSchemaApiSchema.parse(data)

  return parsedData.data.results
}

export type HistoricalBiomarkerResultMap = Record<
  string,
  HistoricalBiomarkerResult[]
>

export function transformHistoricalResultData(
  history: HistoricalBiomarkerResults
): HistoricalBiomarkerResultMap {
  const result: HistoricalBiomarkerResultMap = {}
  history.biomarkers.map((b) => {
    if (result[b.id] === undefined) {
      result[b.id] = []
    }
    result[b.id].push(b)
  })
  return result
}

export const fetchAttachmentsForResult = async (
  resultUuid?: string,
  profileUuid?: string,
  orgUuid?: string
): Promise<File[]> => {
  // setTimeout(async () => {

  const response = await authFetch(`${import.meta.env.VITE_API_URL}/graphql`, {
    method: 'POST',
    headers: {
      'content-type': 'application/json',
    },
    data: JSON.stringify({
      query: `{
          filesForResult(uuid: "${resultUuid}" profileUuid: "${profileUuid}" ${orgUuid ? `organisationUuid: "${orgUuid}"` : ''}) {
            uuid
            filename
            extension
            filesize
            createdAt
          }}`,
    }),
  })
  const data = response.data
  // console.log("got the data", data);
  const resultFiles = FileListSchema.parse(data.data.filesForResult)
  // const referralFiles = FileListSchema.parse(data.data.filesForReferral);
  return resultFiles || []
}

export interface FileGroups {
  referralFiles: File[]
  resultFiles: File[]
}
