import {
  Combobox,
  ComboboxInput,
  ComboboxOption,
  ComboboxOptions,
} from '@headlessui/react'
import { EyeIcon, EyeSlashIcon, TrashIcon } from '@heroicons/react/24/outline'
import { ReactFormExtendedApi, useForm, useStore } from '@tanstack/react-form'
import React, { Fragment, useEffect, useState } from 'react'
import { z } from 'zod'
import { ThemeInterface } from '../../lib/interfaces'
import {
  BiomarkerItem,
  BiomarkerList,
  OptimalRangeCriteriaSchema,
  OptimalRangeGenderCriteria,
  OptimalRangeSchema,
  OptimalRangeSpecSchema,
} from '../../lib/validators'
import { Button } from '../form/button/Button'
import { InputAdornment } from '../form/InputAdornment'
import { fieldTextColorClass, inputClass, selectClass } from '../form/styles'
import BoundedValueBar from '../result/BoundedValueBar'

/**
 * Note:
 *
 * The form schema extends the API schema to handle form-specific requirements:
 * - Allows `string` inputs for fields like `low` and `high`, which are later transformed and validated.
 * - Ensures empty strings are treated as `null` for consistency.
 * - Maintains compatibility with the API while adapting to the needs of the form input system.
 */

const stringOrNumberToString = z
  .union([z.string(), z.number()])
  .nullable()
  .transform((value) => {
    if (value === null || value === '') return null // Return null for null or empty strings
    return value.toString() // Convert numbers to strings
  })
  .refine(
    (value) => value === null || /^-?\d+(\.\d+)?$/.test(value), // Validate as null or valid number format
    { message: 'Must be a valid number' }
  )

const FormBiomarkerRangeSchema = z
  .object({
    low: stringOrNumberToString.optional().nullable(),
    high: stringOrNumberToString.optional().nullable(),
  })
  .refine((range) => range.low !== null || range.high !== null, {
    message: 'Either low or high must be defined for the range.',
  })
  .refine(
    (range) => {
      const low = range.low !== null ? Number(range.low) : -Infinity
      const high = range.high !== null ? Number(range.high) : Infinity
      return low <= high
    },
    { message: 'The high value must not be lower than the low value.' }
  )

const FormOptimalSpecSchema = OptimalRangeSpecSchema.extend({
  ranges: z.array(FormBiomarkerRangeSchema),
})

/**
 * This schema extends the API schema to handle form-specific requirements.
 * e.g. it allows `string` inputs for fields like `low` and `high`, which are later transformed and validated.
 * It also omits fields that are not relevant to the form.
 * i.e. it removes `createdAt`, `updatedAt`, and `totalBiomarkers` fields from the form.
 */
const FormOptimalRangeSchema = OptimalRangeSchema.extend({
  specs: z.array(FormOptimalSpecSchema),
  uuid: z.string().uuid().optional(), // Optional for create/edit
}).omit({
  createdAt: true,
  updatedAt: true,
  totalBiomarkers: true,
})

const FormSchema = z.object({
  optimalRange: FormOptimalRangeSchema,
})

/**
 * This schema reflects what is submitted from reversing transformations made in the form.
 * e.g. converting `string` inputs back to `number` for `low` and `high` fields.
 */
const SubmitValueSchema = z.object({
  optimalRange: FormOptimalRangeSchema.extend({
    specs: OptimalRangeSchema.shape.specs,
  }),
})

type OptimalRangeFormData = z.infer<typeof FormSchema>
export type OptimalRangeSubmitData = z.infer<typeof SubmitValueSchema>

// We can only use numeric biomarkers in the optimal range form
export type OptimalRangeBiomarkerItem = BiomarkerItem & { valueType: 'numeric'}

interface OptimalRangeFormProps {
  biomarkers: readonly OptimalRangeBiomarkerItem[]
  optimalRange: OptimalRangeSubmitData['optimalRange']
  theme?: ThemeInterface
  onSubmit: (value: OptimalRangeSubmitData) => Promise<void>
}

export const OptimalRangeForm: React.FC<OptimalRangeFormProps> = ({
  biomarkers,
  optimalRange,
  onSubmit,
}) => {
  const defaultValues = React.useMemo(
    () => ({
      optimalRange: {
        ...optimalRange,
        specs: optimalRange.specs.map((spec) => ({
          ...spec,
          ranges: spec.ranges.map((range) => ({
            ...range,
            low: range.low !== null ? range.low.toString() : null,
            high: range.high !== null ? range.high.toString() : null,
          })),
        })),
      },
    }),
    [optimalRange]
  )

  const form = useForm<OptimalRangeFormData>({
    defaultValues: defaultValues,
    onSubmit: async (formData) => {
      const transformedData = {
        ...formData.value,
        optimalRange: {
          ...formData.value.optimalRange,
          specs: formData.value.optimalRange.specs.map((spec) => ({
            ...spec,
            ranges: spec.ranges.map((range) => ({
              ...range,
              low: range.low !== null ? Number(range.low) : null,
              high: range.high !== null ? Number(range.high) : null,
            })),
          })),
        },
      }

      try {
        await onSubmit(transformedData)
      } catch (error) {
        console.error('Error submitting form:', error)
      }
    },
    validators: {
      onChange: FormSchema,
      onSubmit: FormSchema,
      onBlur: FormSchema,
      onMount: FormSchema,
    },
  })

  useEffect(() => {
    form.reset()
  }, [form, defaultValues])

  const specsState = useStore(
    form.store,
    (state) => state.values.optimalRange.specs || []
  )
  const totalBiomarkers = specsState.length

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault()
        e.stopPropagation()
        form.handleSubmit()
      }}
      className="p-6 space-y-8"
    >
      {/* Header Section */}
      <div className="flex justify-between">
        <div className="flex flex-col">
          <form.Field
            name="optimalRange.name"
            validators={{ onChange: OptimalRangeSchema.shape.name }}
          >
            {(field) => (
              <Fragment>
                <label htmlFor={field.name} className="text-sm font-semibold">
                  Title
                </label>
                <input
                  id={field.name}
                  name={field.name}
                  value={field.state.value}
                  onChange={(event) => field.handleChange(event.target.value)}
                  className={`${inputClass} mt-1`}
                />
                {field.state.meta.isTouched &&
                  field.state.meta.errors.length > 0 && (
                    <p className="text-red-500 text-sm mt-2">
                      {field.state.meta.errors.join(', ')}
                    </p>
                  )}
              </Fragment>
            )}
          </form.Field>
        </div>
        <div className="flex items-center mt-1">
          <span>{totalBiomarkers}</span>
          <img
            src="https://cdn.prod.website-files.com/660b49a63d2aa9315e77cf0b/671b1b4171607bb0e0cd44ae_Biomarker%20icon.svg"
            alt="Biomarker icon"
            className="ml-2 w-4 h-4"
          />
        </div>
      </div>

      {/* Criteria Section */}
      <div>
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
          <CriteriaField form={form} />
        </div>
      </div>

      {/* Biomarker Specs Section */}
      <div>
        <div className="space-y-4">
          <SpecArrayField form={form} biomarkers={biomarkers} />
        </div>
      </div>

      {/* Save and Reset Buttons */}
      <form.Subscribe
        selector={(state) => [
          state.canSubmit,
          state.isSubmitting,
          state.isDirty,
        ]}
      >
        {([canSubmit, isSubmitting, isDirty]) => {
          if (!isDirty) return null
          return (
            <div className="flex gap-4 justify-end">
              <Button
                color="blood"
                label="Reset"
                type="button"
                onClick={() => form.reset()}
                disabled={isSubmitting}
              />
              <Button
                color="jade"
                label="Save"
                type="submit"
                disabled={!canSubmit || isSubmitting}
              />
            </div>
          )
        }}
      </form.Subscribe>
    </form>
  )
}

interface CriteriaFieldProps {
  form: ReactFormExtendedApi<OptimalRangeFormData>
}

const CriteriaField: React.FC<CriteriaFieldProps> = ({ form }) => {
  return (
    <form.Field
      name={`optimalRange.criteria.gender`}
      validators={{
        onChange: OptimalRangeCriteriaSchema.shape.gender,
      }}
    >
      {(field) => (
        <div className="space-y-2">
          <label htmlFor="gender" className="text-sm font-semibold">
            Gender
          </label>
          <select
            id="gender"
            name={field.name}
            value={field.state.value?.join(',')}
            onChange={(e) =>
              field.handleChange(
                (e.target.value === ''
                  ? null
                  : e.target.value.split(',')) as OptimalRangeGenderCriteria
              )
            }
            className={`${selectClass} w-fit`}
          >
            <option value="">N/A (all)</option>
            <option value="male">Male</option>
            <option value="female">Female</option>
          </select>
          {field.state.meta.isTouched && field.state.meta.errors.length > 0 && (
            <p className="text-red-500 text-sm">
              {field.state.meta.errors.join(', ')}
            </p>
          )}
        </div>
      )}
    </form.Field>
  )
}

interface SpecArrayFieldProps {
  form: ReactFormExtendedApi<OptimalRangeFormData>
  biomarkers: readonly OptimalRangeBiomarkerItem[]
}

const SpecArrayField: React.FC<SpecArrayFieldProps> = ({
  form,
  biomarkers,
}) => {
  const specArrayKey = 'optimalRange.specs' as const
  const [biomarkerQuery, setBiomarkerQuery] = useState('')

  // Get the list of already selected biomarkers in `specs`
  const existingBiomarkerIds = useStore(form.store, (state) =>
    state.values.optimalRange.specs.map((spec) => spec.biomarkerId)
  )

  // Filter available biomarkers: exclude existing and apply search
  const filteredBiomarkers = biomarkers.filter(
    (biomarker) =>
      !existingBiomarkerIds.includes(biomarker.id) &&
      biomarker.name.toLowerCase().includes(biomarkerQuery.toLowerCase())
  )

  const allBiomarkersSelected =
    existingBiomarkerIds.length === biomarkers.length

  return (
    <form.Field name={specArrayKey} mode="array">
      {(field) => (
        <Fragment>
          {/* Render specs */}
          {field.state.value.map((_, i) => (
            <div key={`${specArrayKey}[${i}]`} className="border-b pb-4">
              <SpecField
                form={form}
                index={i}
                biomarkers={biomarkers}
                onRemove={() => field.removeValue(i)}
              />
            </div>
          ))}

          {/* Conditionally show biomarkers to add. Otherwise show a all biomarkers selected message */}
          <div className="mt-4">
            {!allBiomarkersSelected ? (
              <>
                <Combobox
                  immediate={true}
                  onChange={(selectedBiomarker: BiomarkerList[0]) => {
                    field.pushValue({
                      biomarkerId: selectedBiomarker.id,
                      ranges: [{ low: null, high: null }],
                      unit: selectedBiomarker.unit ?? '',
                    })
                    setBiomarkerQuery('')
                    setTimeout(() => {
                      // Focus on the new field
                      const newIndex = field.state.value.length - 1
                      const newFieldElement = document.getElementById(
                        `optimalRange.specs[${newIndex}].ranges[0].low`
                      )
                      newFieldElement?.scrollIntoView({
                        behavior: 'smooth',
                        block: 'center',
                      })
                      newFieldElement?.focus()
                    }, 0)
                  }}
                >
                  <div className="relative mt-1">
                    <ComboboxInput
                      className="cursor-pointer border-0 rounded-md py-2 px-3 focus:outline-none ring-1 ring-inset ring-gray-dark/30 focus:ring-2 shadow-sm focus:ring-gray-dark/30 dark:bg-dark-gray-light dark:text-white"
                      onChange={(event) =>
                        setBiomarkerQuery(event.target.value)
                      }
                      placeholder="Add a biomarker"
                    />
                    <ComboboxOptions className="absolute z-10 mt-1 max-h-60 min-w-fit max-w-sm overflow-auto rounded-md bg-white py-1 text-sm shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                      {filteredBiomarkers.map((biomarker) => (
                        <ComboboxOption
                          key={biomarker.id}
                          value={biomarker}
                          className={({ focus }) =>
                            `cursor-pointer select-none relative py-2 pl-3 pr-9 ${
                              focus ? 'bg-gray-400 text-white' : 'text-gray-900'
                            }`
                          }
                        >
                          {({ focus }) => (
                            <span
                              className={`block break-words ${
                                focus ? 'font-medium' : 'font-normal'
                              }`}
                            >
                              {biomarker.name} ({biomarker.unit})
                            </span>
                          )}
                        </ComboboxOption>
                      ))}
                    </ComboboxOptions>
                  </div>
                </Combobox>
              </>
            ) : (
              <div className="px-3 py-2 text-gray-500 text-center">
                🎉 Wow, you got all of the biomarkers covered! Bloody good
                effort! 🎉
              </div>
            )}
          </div>
        </Fragment>
      )}
    </form.Field>
  )
}

interface SpecFieldProps {
  form: ReactFormExtendedApi<OptimalRangeFormData>
  index: number
  biomarkers: readonly OptimalRangeBiomarkerItem[]
  onRemove: (index: number) => void
}

const SpecField: React.FC<SpecFieldProps> = ({
  form,
  index,
  biomarkers,
  onRemove,
}) => {
  const [showPreview, setShowPreview] = useState(false)
  const spec = useStore(
    form.store,
    (state) => state.values.optimalRange.specs[index]
  )
  const biomarker = biomarkers.find(
    (biomarker) => biomarker.id === spec.biomarkerId
  )

  // Remove the spec if the associated biomarker is not found
  // This can happen if the biomarker is removed from the list of biomarkers
  if (!biomarker) {
    onRemove(index)
    return null // Stop rendering this component
  }

  const previewRange = spec.ranges[0]
  const parseValidNumber = (value?: string | null) =>
    value !== null &&
    value !== undefined &&
    value !== '' &&
    !isNaN(Number(value))
      ? Number(value)
      : null

  const previewLow = parseValidNumber(previewRange.low)
  const previewHigh = parseValidNumber(previewRange.high)

  return (
    <div className="flex flex-col">
      <div className="flex flex-col gap-4">
        {/* Header Container: Biomarker Details and Actions */}
        <div className="flex justify-between items-start gap-4">
          {/* Biomarker Details */}
          <div className="flex flex-col">
            <div className="flex items-center gap-2">
              <h3 className="text-sm font-semibold">{biomarker.name}</h3>
              {/* Preview Button */}
              <Button
                color="plain"
                type="button"
                onClick={() => setShowPreview(!showPreview)}
                aria-label={showPreview ? 'Hide Preview' : 'Show Preview'}
              >
                {showPreview ? (
                  <EyeSlashIcon className="w-4 h-4 text-gray-800" />
                ) : (
                  <EyeIcon className="w-4 h-4 text-gray-500" />
                )}
              </Button>
            </div>
            <p className={`text-xs ${fieldTextColorClass}`}>Unit: {biomarker.unit}</p>
          </div>

          {/* Delete Button */}
          <div className="flex items-start">
            <Button
              color="plain"
              type="button"
              onClick={() => onRemove(index)}
              aria-label="Delete Biomarker"
            >
              <TrashIcon className="w-4 h-4 text-red-500 hover:text-red-700" />
            </Button>
          </div>
        </div>

        {/* Range */}
        <div className="flex flex-col">
          <SpecRangeArrayField form={form} index={index} />
        </div>
      </div>

      {/* Preview */}
      {showPreview && (
        <div className="mt-8 mb-4">
          <BoundedValueBar
            value={11} // TODO: Allow for custom value
            lowerBound={previewLow}
            upperBound={previewHigh}
          />
        </div>
      )}
    </div>
  )
}

interface SpecRangeArrayFieldProps {
  form: ReactFormExtendedApi<OptimalRangeFormData>
  index: number
}

const SpecRangeArrayField: React.FC<SpecRangeArrayFieldProps> = ({
  form,
  index,
}) => {
  const specRangeArrayKey = `optimalRange.specs[${index}].ranges` as const

  return (
    <form.Field name={specRangeArrayKey} mode="array">
      {(field) => (
        <div className="flex flex-col space-y-2">
          {field.state.value.map((_, rangeIndex) => {
            return (
              <SpecRangeField
                key={`${specRangeArrayKey}[${rangeIndex}]`}
                form={form}
                specIndex={index}
                rangeIndex={rangeIndex}
              />
            )
          })}
        </div>
      )}
    </form.Field>
  )
}

interface SpecRangeField {
  form: ReactFormExtendedApi<OptimalRangeFormData>
  specIndex: number
  rangeIndex: number
}

const SpecRangeField: React.FC<SpecRangeField> = ({
  form,
  specIndex,
  rangeIndex,
}) => {
  const rangeKeyBase =
    `optimalRange.specs[${specIndex}].ranges[${rangeIndex}]` as const
  const spec = useStore(
    form.store,
    (state) => state.values.optimalRange.specs[specIndex]
  )
  const { high, low } = spec.ranges[rangeIndex]
  const unit = spec.unit

  // Cast empty strings to null and convert to numbers if valid
  const lowValue = low === '' ? null : low !== null ? Number(low) : null
  const highValue = high === '' ? null : high !== null ? Number(high) : null

  // Validate high and low using FormBiomarkerRangeRangeSchema
  const rangeValidation = FormBiomarkerRangeSchema.safeParse({
    low: lowValue,
    high: highValue,
  })

  const rangeErrors = !rangeValidation.success
    ? rangeValidation.error.errors.map((error) => error.message)
    : []

  // Generate range description only if there are no range errors
  let rangeDescription = undefined

  if (rangeErrors.length === 0) {
    if (lowValue !== null && highValue !== null) {
      rangeDescription = `The optimal level is between ${lowValue} and ${highValue} ${unit}.`
    } else if (lowValue !== null) {
      rangeDescription = `The optimal level is greater than ${lowValue} ${unit}.`
    } else if (highValue !== null) {
      rangeDescription = `The optimal level is less than ${highValue} ${unit}.`
    }
  }

  return (
    <div className="flex flex-col gap-2 text-sm">
      <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
        {/* Low Input */}
        <form.Field name={`${rangeKeyBase}.low`}>
          {(lowField) => (
            <div>
              <InputAdornment
                id={`${rangeKeyBase}.low`}
                value={lowField.state.value || ''}
                onChange={(e) => lowField.handleChange(e.target.value)}
                className={`w-full ${
                  rangeErrors.length > 0 ? 'border-red-500' : ''
                }`}
                prefix={'Low'}
                suffix={unit ?? undefined}
              />
            </div>
          )}
        </form.Field>

        {/* High Input */}
        <form.Field name={`${rangeKeyBase}.high`}>
          {(highField) => (
            <div>
              <InputAdornment
                id={`${rangeKeyBase}.high`}
                value={highField.state.value || ''}
                onChange={(e) => highField.handleChange(e.target.value)}
                className={`w-full ${
                  rangeErrors.length > 0 ? 'border-red-500' : ''
                }`}
                prefix={'High'}
                suffix={unit ?? undefined}
              />
            </div>
          )}
        </form.Field>
      </div>

      {/* Range Description */}
      {rangeDescription && (
        <div className={`text-xs ${fieldTextColorClass}`}>
          <p>{rangeDescription}</p>
        </div>
      )}

      {/* Range-Level Errors */}
      {rangeErrors.length > 0 && (
        <div className="text-red-500 text-xs">
          {rangeErrors.map((error, index) => (
            <p key={index}>{error}</p>
          ))}
        </div>
      )}
    </div>
  )
}
