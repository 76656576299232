import { Fragment, useEffect, useState } from 'react'

import PageLoader from '../../../layout/PageLoader'
import PageSection from '../../../layout/PageSection'
import { useOrganisationContext } from '../../../context/OrganisationContext'
import { getProductsForOrganisation } from '../../../../lib/organisation'
import {
  Organisation,
  ProductWithTestsAndBiomarkersSelected,
} from '../../../../lib/validators'
import { PageHeading } from '../../../layout/PageHeading'
import { Link } from 'react-router-dom'
import { getBundleUrl, getCreateTestBundleUrl } from '../../../../lib/routes'
import { getPrettyDate, setDocumentTitle } from '../../../../lib/utils'
import OrgPage from '../../../OrgPage'
import { LinkButton } from '../../../form/button/LinkButton'
import { StatusTag } from '../../../layout/StatusTag'
import { StatusTagColors } from '../../../../lib/interfaces'
import posthog from '../../../../lib/posthog'
import 'react-tooltip/dist/react-tooltip.css'

import { BundleActionsDropdown } from './dropdowns/BundleActionsDropdown'
import { TestTurnaroundTime } from '../../../ui/TestTurnaroundTime'

export function OrgTestBundles() {
  const [loading, setLoading] = useState(false)
  const { org, theme } = useOrganisationContext()
  const [packages, setPackages] = useState<
    ProductWithTestsAndBiomarkersSelected[] | null
  >(null)

  setDocumentTitle(`Test Bundles  | ${org && org?.name}`, 'BRANDED')

  useEffect(() => {
    if (org && !loading && packages === null) {
      setLoading(true)
      getProductsForOrganisation(org.uuid).then((products) => {
        setPackages(products)
        setLoading(false)
      })
    }
  }, [org, packages, loading])

  const getBundleActionDropdown = (
    pack: ProductWithTestsAndBiomarkersSelected,
    org: Organisation
  ) => {
    return (
      <BundleActionsDropdown
        editControls={true}
        bundleId={pack.id}
        bundleName={pack.name}
        org={org}
        theme={org.theme || undefined}
        disabled={false}
        buttonLabel="Actions"
      ></BundleActionsDropdown>
    )
  }

  return (
    <Fragment>
      {org && (
        <OrgPage
          org={org}
          tabs={[
            {
              name: 'Test Bundles',
            },
          ]}
          className="pb-20"
        >
          {loading && <PageLoader theme={theme || undefined}></PageLoader>}
          {!loading && org && (
            <PageSection>
              <PageHeading
                title={`${org?.name} Test Bundles`}
                action={
                  <Fragment>
                    {posthog.isFeatureEnabled('can-create-org-test') && (
                      <LinkButton
                        url={getCreateTestBundleUrl(org.id)}
                        label="Create Test Bundle"
                        internal={true}
                        theme={theme || undefined}
                      ></LinkButton>
                    )}
                  </Fragment>
                }
              ></PageHeading>
              {!packages ||
                (packages.length === 0 && <div>No test bundles created.</div>)}
              {packages && packages.length > 0 && (
                <div className="grid grid-cols-6 sm:grid-cols-12">
                  {packages.map((pack) => (
                    <Fragment key={`${pack.id}-pack`}>
                      <div className="col-span-4 sm:col-span-4 font-bold min-h-10 sm:border-b  sm:border-gray sm:dark:border-dark-gray-light pt-2 pb-2">
                        <Link to={getBundleUrl(org?.id, pack.id)}>
                          {pack.name}
                        </Link>
                      </div>
                      <div className="col-span-2 font-bold min-h-10 mt-4 sm:hidden block ">
                        {getBundleActionDropdown(pack, org)}
                      </div>

                      <div
                        className={`col-span-6 sm:col-span-4 text-gray-semi-dark content-center mt-2 sm:border-b sm:border-gray sm:dark:border-dark-gray-light ${!pack.description ? '!mt-0' : 'min-h-10'}`}
                      >
                        <div>
                          {pack.description && pack.description.length > 200
                            ? `${pack.description?.substring(0, 200)}...`
                            : pack.description}
                          <div>
                            {pack.turnAroundTimeAverage && (
                              <TestTurnaroundTime
                                hideHeading={true}
                                turnaroundTimes={pack.turnAroundTimeAverage}
                                showMissingText={false}
                              ></TestTurnaroundTime>
                            )}
                          </div>
                        </div>
                      </div>
                      <div
                        className={`col-span-6 sm:col-span-1 text-gray-semi-dark mt-2 border-b border-gray dark:border-dark-gray-light pb-4 sm:pb-0  ${pack.internal ? '!mt-0 !pb-0' : 'min-h-10'}`}
                      >
                        {!pack.internal && (
                          <StatusTag
                            color={StatusTagColors.ORANGE}
                            label="Template"
                          ></StatusTag>
                        )}
                      </div>
                      <div className=" hidden sm:block sm:col-span-2 text-center min-h-10 mt-2 border-b border-gray dark:border-dark-gray-light">
                        {getPrettyDate(pack.createdAt)}
                        {/* <p
                        title={pack.tests?.map((t) => t.name).join('\n')}
                        className="truncate text-sm"
                      >
                        {pack.tests?.map((t) => t.shortName).join(', ')}
                      </p> */}
                      </div>
                      <div className="hidden col-span-1 text-left sm:block min-h-10 mt-2 pt-1 border-b border-gray dark:border-dark-gray-light ">
                        {getBundleActionDropdown(pack, org)}
                      </div>
                    </Fragment>
                  ))}
                </div>
              )}
            </PageSection>
          )}
        </OrgPage>
      )}
    </Fragment>
  )
}
