import {
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import '../../../App.css'
import { useUser } from '../../../lib/store'
import TextInputField, { onChangeInterface } from '../../form/TextInputField'
import { authFetch } from '../../../providers/AuthProvider'
// import posthog from '../../../lib/posthog'
import { BiomarkerItem, PdfBiomarkerResult } from '../../../lib/validators'
import SimpleSelectField, {
  SimpleSelectItemProps,
} from '../../form/SimpleSelectField'
import toast from 'react-hot-toast'
import { PageHeading } from '../../layout/PageHeading'
import {
  AcceptedFileTypes,
  FileDrop,
  FileUploadElement,
  UploadDict,
  UploadStatus,
} from '../../form/FileDrop'
import { CheckCircleIcon, DocumentTextIcon } from '@heroicons/react/24/outline'
import { useNavigate } from 'react-router-dom'
import PageSection from '../../layout/PageSection'
import {
  getPrettyDate,
  getPrettyDateAndTime,
  setDocumentTitle,
  simpleMdTransform,
} from '../../../lib/utils'

import { Textarea } from '../../form/Textarea'
import AdminBiomarkerElement from '../../admin/AdminBiomarkerElement'
import Checkbox from '../../form/Checkbox'
import { getResultsDataForProfileAndUuid } from '../../../lib/results'
import AdminPage from './AdminPage'
import PageLoader from '../../layout/PageLoader'
import {
  getBiomarkersForReferralUuid,
  getIssuedReferrals,
  getPathologyProviders,
  getResultStatus,
  ResultStatusTypes,
} from '../../../lib/admin'
import { Button } from '../../form/button/Button'
import Switch from '../../form/Switch'
import { TrashIcon } from '@heroicons/react/24/solid'
import { SelectAdditionalBiomarkerModal } from './SelectAdditionalBiomarkerModal'

interface ReferralSelectItemProps extends SimpleSelectItemProps {
  profileUuid?: string
  organisationUuid?: string
  testName?: string
  uuid?: string
  resultUuid?: string
  reference: string
}

interface UploadedFile {
  filename?: string
  filesize?: number
}

interface BiomarkerRequestPayload {
  id: string
  value: string
  refIntervalHigh?: number | null
  refIntervalLow?: number | null
  refIntervalHighNotes?: string | null
  refIntervalLowNotes?: string | null
  refIntervalMidNotes?: string | null
  description?: string | null
}

enum BiomarkerSource {
  Expected,
  ResultImport,
  Added,
}

export interface BiomarkerMasterItem {
  id: string
  value?: string
  name: string
  refIntervalHigh?: string | null
  refIntervalLow?: string | null
  refIntervalHighNotes?: string | null
  refIntervalLowNotes?: string | null
  refIntervalMidNotes?: string | null
  description?: string | null
  expectedUnit?: string | null
  actualUnit?: string | null
  commonName?: string | null
  expected: BiomarkerSource
  abnormal?: boolean
  valueType: 'numeric' | 'string'
}

export type BiomarkerMasterMap = Record<string, BiomarkerMasterItem>

export default function AdminCreateResultsPage() {
  const currentTime = getPrettyDateAndTime(new Date())

  const user = useUser()
  const navigate = useNavigate()
  const formRef = useRef<HTMLFormElement>(null)
  const [issuedReferrals, setIssuedReferrals] = useState<
    ReferralSelectItemProps[]
  >([])
  const [selectedReferral, setSelectedReferral] =
    useState<ReferralSelectItemProps | null>(null)
  const [profileForSelectedReferral, setProfileForSelectedReferral] = useState<
    string | null
  >(null)
  const [organisationForSelectedReferral, setOrganisationForSelectedReferral] =
    useState<string | null>(null)
  const [selectedPathologyProvider, setSelectedPathologyProvider] = useState<
    string | null
  >(null)
  const [biomarkers, setBiomarkers] = useState<BiomarkerItem[] | null>(null)
  const [pathologyProviders, setPathologyProviders] = useState<
    SimpleSelectItemProps[]
  >([])
  const [resultName, setResultName] = useState<string>('')
  const [bgSummary, setBgSummary] = useState<string>('')
  const [saveEnabled, setSaveEnabled] = useState(false)
  const [initComplete, setInitComplete] = useState(false)
  const [processing, setProcessing] = useState(false)
  const [uploads, setUploads] = useState<UploadDict>({})
  const [existingUploads, setExistingUploads] = useState<UploadedFile[]>([])
  const [preview, setPreview] = useState<string>('')

  const [saveButtonColor, setSaveButtonColor] = useState<string>(
    'bg-bgt-primary/90 hover:bg-bgt-primary'
  )
  const [resultDate, setResultDate] = useState<string>(currentTime)
  const [inReview, setInReview] = useState(false)
  const [loading, setLoading] = useState(false)

  const [skipResultsNotification, setSkipResultsNotification] = useState(false)
  const [complete, setComplete] = useState(false)

  const [showDetail, setShowDetail] = useState(false)
  const [openAddBiomarkerModal, setOpenAddBiomarkerModal] = useState(false)
  const [biomarkerMasterList, setBiomarkerMasterList] =
    useState<BiomarkerMasterMap>({})
  const [formBiomarkerUpdates, setFormBiomarkerUpdates] =
    useState<BiomarkerMasterMap>({})
  const [extractedBiomarkerUpdates, setExtractedBiomarkerUpdates] =
    useState<BiomarkerMasterMap>({})

  const masterListCache = useMemo(() => {
    const finalResult: BiomarkerMasterMap = {}
    Object.values(biomarkerMasterList).map((item) => {
      console.log(item.id, item)
      finalResult[item.id] = {
        ...finalResult[item.id],
        ...item,
      }
    })
    Object.values(extractedBiomarkerUpdates).map((item) => {
      finalResult[item.id] = {
        ...finalResult[item.id],
        ...item,
      }
    })
    Object.values(formBiomarkerUpdates).map((item) => {
      finalResult[item.id] = {
        ...finalResult[item.id],
        ...item,
      }
    })

    console.log('BIOMARKERS', biomarkerMasterList)
    console.log('EXTRACTED', extractedBiomarkerUpdates)
    console.log('FORM', formBiomarkerUpdates)
    console.log('=======', finalResult)
    return finalResult
  }, [biomarkerMasterList, formBiomarkerUpdates, extractedBiomarkerUpdates])

  useEffect(() => {
    setDocumentTitle('Admin: Add Results')
  }, [])
  // first we need to get the current tests without results.

  const cacheFields = useCallback(
    (referenceNumber: string): void => {
      localStorage.setItem(
        `REFERENCE_${referenceNumber}`,
        JSON.stringify(masterListCache)
      )
    },
    [masterListCache]
  )
  const resetCacheFields = (referenceNumber: string) => {
    localStorage.removeItem(`REFERENCE_${referenceNumber}`)
  }
  const restoreCacheFields = (
    referenceNumber: string,
    resumeState?: BiomarkerMasterMap
  ): void => {
    const payload =
      localStorage.getItem(`REFERENCE_${referenceNumber}`) || undefined
    let newState: BiomarkerMasterMap = resumeState || {}
    const parsedJson = payload
      ? (JSON.parse(payload) as BiomarkerMasterMap)
      : {}
    Object.values(parsedJson).map((item) => {
      newState = {
        ...newState,
        [item.id]: {
          ...item,
          expected: newState[item.id]
            ? newState[item.id].expected
            : BiomarkerSource.ResultImport,
        },
      }
    })
    setBiomarkerMasterList(newState)
  }

  useEffect(() => {
    if (!initComplete) {
      getIssuedReferrals().then((data) => {
        setIssuedReferrals(
          data.map((item) => ({
            value: item.reference,
            label: `${item.resultUuid ? '⚠️ ' : ''}${item.customerName} - ${
              item.testName
            } - ${getPrettyDate(item.createdAt)} - ${item.reference} - ${item.status.replace(
              '_',
              ' '
            )}${item.resultUuid ? ' ⚠️' : ''}`,
            profileUuid: item.profileUuid || undefined,
            organisationUuid: item.organisationUuid || undefined,
            testName: item.testName,
            uuid: item.uuid,
            reference: item.reference,
            resultUuid: item.resultUuid || undefined,
          }))
        )
      })
      getPathologyProviders().then((data) => {
        setPathologyProviders(
          data.map((item) => ({
            value: item.id,
            label: item.name,
          }))
        )
      })
      setInitComplete(true)
    }
  }, [initComplete, user, navigate])

  useEffect(() => {
    async function getLatestState() {
      let initialBiomarkerState: BiomarkerMasterMap = {}
      if (selectedReferral !== null) {
        // we need to go and fetch all the biomarkers related to this particular referral, ie look up the test
        if (selectedReferral.uuid) {
          const expectedBiomarkers = await getBiomarkersForReferralUuid(
            selectedReferral.uuid.toString()
          )
          expectedBiomarkers.map((b) => {
            initialBiomarkerState = {
              ...initialBiomarkerState,
              [b.id]: {
                ...b,
                expected: BiomarkerSource.Expected,
                refIntervalHigh: undefined,
                refIntervalLow: undefined,
              },
            }
          })
        }

        if (selectedReferral.resultUuid) {
          const item = await getResultsDataForProfileAndUuid({
            resultUuid: selectedReferral.resultUuid,
            profileUuid: selectedReferral.profileUuid,
            organisationUuid: selectedReferral.organisationUuid,
            include: ['files'],
          })
          console.log('====', item)
          if (item) {
            if (item.status === 'IN_REVIEW') {
              setInReview(true)
            }
            item.biomarkers?.map((item) => {
              initialBiomarkerState = {
                ...initialBiomarkerState,
                [item.id]: {
                  ...item,
                  value:
                    item.valueType === 'numeric'
                      ? item.numericValue.toString()
                      : item.stringValue,
                  refIntervalHigh:
                    item.valueType === 'numeric'
                      ? item.refIntervalHigh?.toString()
                      : null,
                  refIntervalLow:
                    item.valueType === 'numeric'
                      ? item.refIntervalLow?.toString()
                      : null,
                  expected: initialBiomarkerState[item.id]
                    ? initialBiomarkerState[item.id].expected
                    : BiomarkerSource.ResultImport,
                  valueType: item.valueType,
                },
              }
            })

            setSelectedPathologyProvider(item.pathologyProviderId)
            setResultName(item.name)
            setResultDate(getPrettyDateAndTime(item.resultDate))
            if (item.notes) {
              setBgSummary(item.notes)
            }
            if (item.files && item.files.length > 0) {
              setExistingUploads(item.files)
            }
          }
        }
        // apply any saved changes over the top
        restoreCacheFields(selectedReferral.reference, initialBiomarkerState)
      }
    }
    getLatestState()
  }, [selectedReferral])

  useEffect(() => {
    setPreview(simpleMdTransform(bgSummary))
  }, [bgSummary])

  useEffect(() => {
    setResultName(``)
    if (selectedReferral !== null && selectedPathologyProvider !== null) {
      setResultName(`${selectedReferral.testName}`)
      console.log('Do we have some uploads?', Object.keys(uploads).length)
      if (Object.keys(uploads).length > 0) {
        setSaveEnabled(true)
      }
    } else {
      setSaveEnabled(false)
    }
  }, [selectedReferral, selectedPathologyProvider, masterListCache, uploads])

  const handleReferralSelectChange = (data: onChangeInterface) => {
    if (data.value.toString().trim() === '') {
      setSelectedReferral(null)
      resetForm()
      return
    }
    let theSselectedReferral: ReferralSelectItemProps | null = null
    issuedReferrals.forEach((item) => {
      // console.log("item", item);
      if (item.value === data.value.toString()) {
        console.log('we found the referral!', item)
        setProfileForSelectedReferral(item.profileUuid || null)
        setOrganisationForSelectedReferral(item.organisationUuid || null)
        theSselectedReferral = item
        setSelectedReferral(theSselectedReferral)
        return null
      }
    })
  }
  const handlePathologySelectChange = (data: onChangeInterface) => {
    console.log('pathology is', data)
    setSelectedPathologyProvider(
      data.value.toString() || data.label || data.name
    )
  }

  interface CreateResultsFormElement extends HTMLFormElement {
    readonly elements: HTMLFormControlsCollection
  }

  const handleSubmit = async (
    event: React.FormEvent<CreateResultsFormElement>
  ) => {
    event.preventDefault()
    // console.log('data to submit,', biomarkersForSubmission)
    if (selectedReferral) {
      resetCacheFields(selectedReferral?.reference)
    }
    // pre check the values
    Object.values(masterListCache).map((item) => {
      if (item.value === undefined || item.value === null) {
        toast.error(`Missing value for ${item.name}`)
        throw new Error('Exiting now.')
      }
    })

    // event.currentTarget.elements.biomarker;
    const biomarkersToSend: BiomarkerRequestPayload[] = Object.values(
      masterListCache
    ).map((item) => ({
      id: item.id,
      value: item.value!,
      description: item.description,
      refIntervalHigh: item.refIntervalHigh
        ? parseFloat(item.refIntervalHigh)
        : undefined,
      refIntervalHighNotes: item.refIntervalHighNotes,
      refIntervalLow: item.refIntervalLow
        ? parseFloat(item.refIntervalLow)
        : undefined,
      refIntervalLowNotes: item.refIntervalLowNotes,
      refIntervalMidNotes: item.refIntervalMidNotes,
    }))

    const formData: Record<string, unknown> = {
      biomarkers: biomarkersToSend,
      name: resultName,
      comment: bgSummary,
      complete: complete,
      pathologyProviderId: selectedPathologyProvider,
      referralUuid: selectedReferral?.uuid,
      resultDate: `${resultDate}+${(new Date().getTimezoneOffset() / 60) * -100}`,
      skipSendingResultsEmail: !skipResultsNotification,
      inReview: inReview || false,
    }

    setProcessing(true)
    // save the data
    const toastId = toast.loading('Uploading...', {
      duration: 3000,
      icon: '⤴️',
    })

    let fileUuids: string[] = []
    if (Object.keys(uploads).length > 0) {
      fileUuids = await uploadFiles()
      formData['fileUuids'] = fileUuids
    }

    toast.loading('Saving...', {
      duration: 3000,
      icon: '💾',
      id: toastId,
    })

    const result = await authFetch(`${import.meta.env.VITE_API_URL}/graphql`, {
      method: 'POST',
      headers: {
        'content-type': 'application/json',
      },
      data: JSON.stringify({
        query: `
          mutation name($data:CreateResultInput!) {
            createResult(data: $data) {
              uuid
              createdAt
              updatedAt
            }
          }
        `,
        variables: {
          data: formData,
        },
      }),
    })

    const response = result.data
    if (response.errors) {
      toast.error(response.errors[0].message, {
        duration: 5000,
        id: toastId,
        icon: '❌',
      })
      setProcessing(false)
    } else {
      toast.success('Results saved.', {
        duration: 3000,
        id: toastId,
        icon: '✅',
      })
      // reset the fields
      setSelectedReferral(null)
      resetForm()
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      })
    }
  }

  // for results extraction
  const uploadResultsAndExtractData = async (
    items: UploadDict
  ): Promise<void> => {
    const promises: Promise<void>[] = []
    Object.values(items).forEach(async (item) => {
      promises.push(uploadResultAndExtractData(item))
    })
    await Promise.all(promises)
  }
  const uploadResultAndExtractData = async (
    item: FileUploadElement
  ): Promise<void> => {
    const payload = new FormData()
    payload.append('file', item.file)
    if (biomarkers) {
      payload.append(
        'expectedBiomarkers',
        biomarkers?.map((item) => item.id).join(',')
      )
    }

    setLoading(true)
    const toastResponse = toast.loading('Processing results...')
    try {
      const result = await authFetch(
        `${import.meta.env.VITE_API_URL}/pdf-extract`,
        {
          method: 'POST',
          headers: {
            'content-type': 'multipart/form-data',
          },
          data: payload,
        }
      )
      console.log('result is', result.data)
      if (result && result.data) {
        // find which referral is selected and assign it here.
        if (result.data.referenceNumber) {
          const matchingReferrals = issuedReferrals.filter(
            (s) => s.reference === result.data.referenceNumber
          )

          if (matchingReferrals && matchingReferrals.length === 1) {
            setSelectedReferral(matchingReferrals[0])
          } else {
            toast.error(
              `Referral reference number not found: ${result.data.referenceNumber}`
            )
          }
        }
        console.log('err...')
        if (result.data.provider) {
          console.log(
            'set selected pathology provider => ',
            result.data.provider
          )
          setSelectedPathologyProvider(result.data.provider)
        }
        if (result.data.resultsDate) {
          console.log('we have results date => ', result.data.resultsDate)
          setResultDate(result.data.resultsDate)
        }

        if (result.data.biomarkers && result.data.biomarkers.length > 0) {
          toast.success(
            `Extracted ${result.data.biomarkers.length} biomarkers`,
            {
              id: toastResponse,
            }
          )

          const extractedBiomarkers: BiomarkerMasterMap = {}
          const formBiomarkers: BiomarkerMasterMap = formBiomarkerUpdates
          const masterBiomarkers: BiomarkerMasterMap = {}

          result.data.biomarkers.map((item: PdfBiomarkerResult) => {
            console.log(
              'lets update teh biomarker results',
              item,
              biomarkerMasterList
            )
            if (formBiomarkers[item.id]) {
              delete formBiomarkers[item.id]
            }
            if (masterBiomarkers[item.id]) {
              delete masterBiomarkers[item.id]
            }
            extractedBiomarkers[item.id] = {
              ...item,
              expected: biomarkerMasterList[item.id]
                ? biomarkerMasterList[item.id].expected
                : BiomarkerSource.ResultImport,
              value: item.value?.toString(),
              refIntervalHigh: item.refIntervalHigh?.toString(),
              refIntervalLow: item.refIntervalLow?.toString(),
              valueType: item.valueType,
            }
          })
          setBiomarkerMasterList(masterBiomarkers)
          setFormBiomarkerUpdates(formBiomarkers)
          setExtractedBiomarkerUpdates(extractedBiomarkers)
        } else {
          toast.error('Failed to parse any biomarkers.', {
            id: toastResponse,
          })
        }
      }
    } catch (e) {
      console.warn('Something happened', e)
      let message = 'An unknown error occured trying to parse results.'
      if (e instanceof Error) {
        message = e.message
      }
      toast.error(message, {
        id: toastResponse,
      })
    }
    setLoading(false)
    return
  }

  useEffect(() => {
    if (selectedReferral) {
      cacheFields(selectedReferral.reference)
    }
  }, [selectedReferral, cacheFields])

  const uploadFiles = async (): Promise<string[]> => {
    const promises: Promise<string>[] = []
    Object.values(uploads).forEach(async (item) => {
      promises.push(uploadFile(item))
    })
    return await Promise.all(promises)
  }
  const uploadFile = async (item: FileUploadElement): Promise<string> => {
    const payload = new FormData()
    payload.append('file', item.file)
    if (profileForSelectedReferral) {
      payload.append('profileUuid', profileForSelectedReferral)
    }
    if (organisationForSelectedReferral) {
      payload.append('organisationUuid', organisationForSelectedReferral)
    }
    // update its status
    item.status = UploadStatus.Uploading
    setUploads((prev) => ({
      ...prev,
      [item.file.name]: item,
    }))
    const result = await authFetch(`${import.meta.env.VITE_API_URL}/upload`, {
      method: 'POST',
      headers: {
        'content-type': 'multipart/form-data',
      },
      data: payload,
      onUploadProgress: (progressEvent) => {
        console.log('got some upload progress!')
        if (progressEvent && progressEvent.total) {
          const percentCompleted = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          )
          console.log('progress is', item, percentCompleted)
          item.uploadProgress = 100 - percentCompleted
          setUploads((prev) => ({
            ...prev,
            [item.file.name]: item,
          }))
        }
      },
    })
    if (result && result.data && result.data.fileUuid) {
      console.log('we got a file uuid', result.data)
      item.status = UploadStatus.Complete
      item.uuid = result.data.fileUuid
      setUploads((prev) => ({
        ...prev,
        [item.file.name]: item,
      }))
      return result.data.fileUuid
    }
    return ''
  }

  const resetForm = () => {
    formRef.current?.reset()

    setInitComplete(false)
    setProcessing(false)
    setUploads({})
    setSaveEnabled(false)
    setBgSummary('')

    setResultDate('')
    setSelectedPathologyProvider('')
    setExistingUploads([])
    setComplete(false)
    setSkipResultsNotification(false)
    // setBiomarkerResults({})
    setBiomarkers(null)
    // setUnexpectedBiomarkers({})
    setResultName('')
    setBiomarkerMasterList({})
    setFormBiomarkerUpdates({})
    setExtractedBiomarkerUpdates({})
    setInReview(false)
    // setBiomarkersForSubmission({})
    // setPreview("");
  }

  const acceptedFiles: AcceptedFileTypes = {
    'application/pdf': ['.pdf'],
  }

  const handleFileDrop = (files: File[]): void => {
    // setUploads(files);
    const items: UploadDict = {}
    files.forEach((item) => {
      items[item.name] = {
        file: item,
        status: UploadStatus.Ready,
        uploadProgress: 0,
      }
    })
    console.log(items)
    setUploads(items)
  }

  const handleDropResultsFiles = (files: File[]): void => {
    const items: UploadDict = {}
    files.forEach((item) => {
      items[item.name] = {
        file: item,
        status: UploadStatus.Ready,
        uploadProgress: 0,
      }
    })
    if (Object.keys(items).length > 0) {
      uploadResultsAndExtractData(items)
    }
  }

  useEffect(() => {
    if (complete && skipResultsNotification) {
      setSaveButtonColor('bg-mandarin/90 hover:bg-mandarin')
    } else if (complete) {
      setSaveButtonColor('bg-jade/90 hover:bg-jade')
    } else {
      setSaveButtonColor('bg-blood/90 hover:bg-blood')
    }
  }, [complete, skipResultsNotification])

  const getButtonLabel = (): string => {
    let label = 'Save Partial results'
    if (inReview && !complete) {
      label = 'Save Partial results and mark for review'
    }
    if (!complete && skipResultsNotification) {
      label = 'Save Partial results and notify user'
    }
    if (complete && !skipResultsNotification) {
      label = "Save results but don't notify user"
    }
    if (complete && skipResultsNotification) {
      label = 'Save results and notify user'
    }
    return label
  }

  //if (Object.keys(diff(biomarker, biomarkerCopy)).length > 0) {
  const handleValid = useCallback((data: BiomarkerMasterItem) => {
    console.log('we got something', data)

    setFormBiomarkerUpdates((prev) => ({
      ...prev,
      [data.id]: {
        ...data,
      },
    }))
  }, [])

  return (
    <AdminPage>
      <PageSection>
        <form
          className="max-w-5xl"
          onSubmit={handleSubmit}
          ref={formRef}
          id={'bio-top'}
        >
          <PageHeading
            title="Create Results (new)"
            description="Given a test reference number, please complete the form below to provide results to a test"
            action={
              <Fragment>
                {selectedReferral && (
                  <Button
                    onClick={() => {
                      setSelectedReferral(null)
                      resetForm()
                    }}
                    label="Start Over"
                  ></Button>
                )}
              </Fragment>
            }
          ></PageHeading>

          <div className="w-full pt-10">
            <div className="mt-1 text-sm leading-6 text-gray-600 pb-5">
              <SimpleSelectField
                label="Referral Reference Code"
                name="referralUuid"
                firstItemText="Select reference code"
                options={issuedReferrals}
                handleChange={handleReferralSelectChange}
                value={selectedReferral ? selectedReferral.reference : ''}
              />
            </div>
            {selectedReferral && (
              <div className="mt-1 text-sm leading-6 text-gray-600 pb-5">
                <FileDrop
                  acceptedFileTypes={acceptedFiles}
                  multiple={true}
                  onDropCallback={handleDropResultsFiles}
                  label="Drop results here to extract results."
                />
              </div>
            )}
            {selectedReferral && (
              <Fragment>
                <div className="mt-1 text-sm leading-6 text-gray-600 pb-5">
                  <SimpleSelectField
                    label="Pathology Lab"
                    name="pathologyProviderId"
                    firstItemText="Select pathology lab"
                    value={selectedPathologyProvider || ''}
                    options={pathologyProviders}
                    required
                    handleChange={handlePathologySelectChange}
                  />
                </div>
                <div className="mt-1 text-sm leading-6 text-gray-600 pb-5">
                  <TextInputField
                    id={resultName}
                    label="Results name (displayed to the user in the Results list page)"
                    value={resultName}
                    name="name"
                    type="text"
                    required={true}
                    handleChange={(data: onChangeInterface) =>
                      setResultName(data.value.toString())
                    }
                  />
                </div>
                <div className="mt-1 text-sm leading-6 text-gray-600 pb-5">
                  <TextInputField
                    id="resultDate"
                    label="Results Date"
                    name="resultDate"
                    type="datetime-local"
                    max={currentTime}
                    required={true}
                    value={resultDate}
                    handleChange={(data: onChangeInterface) =>
                      setResultDate(data.value.toString())
                    }
                  />
                </div>
                {biomarkers !== null && biomarkers.length > 0 && (
                  <h3 className="text-base font-semibold leading-7 text-gray-900  dark:text-white">
                    Biomarkers for referral
                  </h3>
                )}
                <Switch
                  name="showDetail"
                  checked={showDetail || false}
                  label="Display Detailed Info"
                  tooltip="If this is checked, then all the notes and descriptions appear below"
                  onChange={(value) => {
                    setShowDetail(value)
                  }}
                ></Switch>

                {masterListCache !== null &&
                  Object.keys(masterListCache).length > 0 &&
                  Object.values(masterListCache).map((item, k) => (
                    <div
                      key={`biomarker-${item.id}-${k}`}
                      id={`bio-${item.id}`}
                      className="mt-1 text-sm leading-6 text-gray-600 pt-3 pb-5 border-b border-b-gray-dark/80"
                    >
                      <AdminBiomarkerElement
                        showDescriptionFields={showDetail}
                        biomarker={item}
                        handleValid={handleValid}
                      ></AdminBiomarkerElement>
                    </div>
                  ))}

                {masterListCache &&
                  Object.keys(masterListCache).length === 0 && (
                    <Fragment>
                      <div>
                        No biomakers found for the set of tests associated with
                        this referral
                      </div>
                    </Fragment>
                  )}
                <Button
                  label="Add Biomarker"
                  classNames="mt-2"
                  onClick={() => {
                    setOpenAddBiomarkerModal(true)
                  }}
                ></Button>
                <SelectAdditionalBiomarkerModal
                  show={openAddBiomarkerModal}
                  setShow={setOpenAddBiomarkerModal}
                  exclude={Object.keys(biomarkerMasterList)}
                  onAdd={(biomarker) => {
                    // console.log('Add this biomarker', biomarker)
                    setBiomarkerMasterList((prev) => ({
                      ...prev,
                      [biomarker.id]: {
                        ...biomarker,
                        expected: BiomarkerSource.Added,
                        refIntervalHigh: null,
                        refIntervalLow: null,
                      },
                    }))
                    // setAddedBiomarkers((prev) => ({
                    //   ...prev,
                    //   [biomarker.id]: {
                    //     ...biomarker,
                    //     refIntervalHigh: null,
                    //     refIntervalLow: null,
                    //   },
                    // }))
                  }}
                ></SelectAdditionalBiomarkerModal>

                <div className="mt-8 text-sm leading-6 text-gray-600 pb-5">
                  <Textarea
                    id="comment"
                    name="comment"
                    label="Bloody Good Summary"
                    handleChange={(e) => setBgSummary(e.value.toString())}
                    value={bgSummary}
                  ></Textarea>
                </div>

                <div className="preview rounded-md bg-gray-light dark:bg-dark-gray-light dark:text-white p-2 mb-4">
                  <label className="block text-sm font-medium leading-6 text-gray-dark mb-2">
                    Summary preview
                  </label>
                  <div dangerouslySetInnerHTML={{ __html: preview }}></div>
                </div>
                <div className="mt-1 text-sm leading-6 text-gray-600 pb-5">
                  <FileDrop
                    acceptedFileTypes={acceptedFiles}
                    multiple={true}
                    onDropCallback={handleFileDrop}
                  />
                  {Object.keys(uploads).length > 0 && (
                    <div className="mt-4">
                      {Object.values(uploads).map((file, idx) => (
                        <FileDropFile
                          key={`file-${idx}`}
                          filesize={file.file.size}
                          filename={file.file.name}
                          // file={file.file}
                          uploadProgress={file.uploadProgress}
                          status={file.status}
                        ></FileDropFile>
                      ))}
                    </div>
                  )}
                  {Object.keys(existingUploads).length > 0 && (
                    <div className="mt-4">
                      {Object.values(existingUploads).map((file, idx) => (
                        <FileDropFile
                          key={`file-${idx}`}
                          filesize={file.filesize}
                          filename={file.filename}
                          // file={file.file}
                          uploadProgress={100}
                          status={UploadStatus.Complete}
                        ></FileDropFile>
                      ))}
                    </div>
                  )}
                </div>
              </Fragment>
            )}
          </div>
          {selectedReferral && (
            <div>
              <div className="mt-1 text-sm leading-6 text-gray-600 pb-5">
                <Checkbox
                  id="inReview"
                  name="inReview"
                  type="checkbox"
                  checked={inReview}
                  defaultChecked={inReview}
                  disabled={!saveEnabled || processing}
                  onChange={(e) => {
                    setInReview(e.strValue === 'true')
                    if (e.strValue === 'true') {
                      setComplete(false)
                      setSkipResultsNotification(false)
                    }
                    // confirmSendToUser(e)
                  }}
                  checkboxLabel={<b>Requires Review</b>}
                ></Checkbox>
              </div>
              <div className="mt-1 text-sm leading-6 text-gray-600 pb-5">
                <Checkbox
                  id="complete"
                  name="complete"
                  type="checkbox"
                  disabled={!saveEnabled || processing}
                  checked={complete}
                  onChange={(e) => {
                    setComplete(e.strValue === 'true')
                    if (e.strValue === 'true') {
                      setInReview(false)
                      setSkipResultsNotification(true)
                    }

                    // confirmSendToUser(e)
                  }}
                  checkboxLabel={<b>⚠️ Mark referral as complete ⚠️</b>}
                ></Checkbox>
              </div>
              <div className="mt-1 text-sm leading-6 text-gray-600 pb-5">
                <Checkbox
                  id="skipSendingResultsEmail"
                  name="skipSendingResultsEmail"
                  type="checkbox"
                  disabled={inReview}
                  checked={skipResultsNotification}
                  onChange={(e) => {
                    setSkipResultsNotification(e.strValue === 'true')
                    // confirmSendToUser(e)
                  }}
                  checkboxLabel={
                    <Fragment>
                      {complete && (
                        <span>Send completed results notification email.</span>
                      )}
                      {!complete && (
                        <span>Inform user of partial results via email.</span>
                      )}
                    </Fragment>
                  }
                ></Checkbox>
              </div>

              <div
                className="mt-6 flex items-center justify-end gap-x-6"
                id={'bio-bottom'}
              >
                <button
                  type="button"
                  onClick={() => {
                    resetForm()
                    setSelectedReferral(null)
                  }}
                  className="text-sm font-semibold leading-6 text-gray-900  dark:text-white"
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className={`rounded-md  px-3 py-2 text-sm font-semibold text-white shadow-sm ${saveButtonColor} focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 disabled:bg-gray-dark`}
                  disabled={!saveEnabled || processing}
                >
                  {getButtonLabel()}
                </button>
              </div>
            </div>
          )}
        </form>
        <ResultSummary
          key={'result-summary'}
          biomarkers={masterListCache}
          onRemove={(biomarkerId, expected) => {
            console.log(`Remove ${biomarkerId} from all the lists.`, expected)

            const tmp: BiomarkerMasterMap = {}
            Object.values(biomarkerMasterList).map((i) => {
              if (i.id !== biomarkerId) {
                tmp[i.id] = i
              }
            })
            const tmp2: BiomarkerMasterMap = {}
            Object.values(extractedBiomarkerUpdates).map((i) => {
              if (i.id !== biomarkerId) {
                tmp2[i.id] = i
              }
            })
            const tmp3: BiomarkerMasterMap = {}
            Object.values(formBiomarkerUpdates).map((i) => {
              if (i.id !== biomarkerId) {
                tmp3[i.id] = i
              }
            })
            setFormBiomarkerUpdates(tmp3)
            setBiomarkerMasterList(tmp)
            setExtractedBiomarkerUpdates(tmp2)
          }}
          onScrollTo={(bio, offset) => {
            console.log('scroll to', bio)
            const element = document.getElementById(`bio-${bio}`)
            if (!element) {
              return
            }

            // also docus the input field
            const inputElement = document.getElementById(`biomarker_${bio}`)
            inputElement?.focus()

            const headerOffset = 55
            const elementPosition = element.getBoundingClientRect().top
            const offsetPosition =
              elementPosition +
              (window.scrollY || window.pageYOffset) -
              headerOffset

            window.scrollTo({
              top: offset !== undefined ? offset : offsetPosition,
              behavior: 'smooth',
            })
          }}
        ></ResultSummary>
      </PageSection>
      {loading && <PageLoader></PageLoader>}
    </AdminPage>
  )
}

interface FileDropFileProps {
  // file: File;
  filename?: string
  filesize?: number
  uploadProgress: number
  status: UploadStatus
}

export function FileDropFile({
  filename,
  filesize,
  uploadProgress,
  status,
}: FileDropFileProps) {
  const getSize = (): string => {
    if (filesize === undefined) {
      return 'unknown size'
    }
    return `${Math.round(filesize / 1000)}kb`
  }
  return (
    <div
      className={`p-4 border-solid border-2 border-gray-dark/20 rounded-lg mb-2 flex relative`}
    >
      <div className="size-10 mr-2">
        {status === UploadStatus.Ready && (
          <DocumentTextIcon width={40} className=""></DocumentTextIcon>
        )}
        {status === UploadStatus.Complete && (
          <CheckCircleIcon width={40} className=""></CheckCircleIcon>
        )}
        {status === UploadStatus.Uploading && (
          <div className="size-8 relative">
            <svg
              className="size-full"
              width="36"
              height="36"
              viewBox="0 0 36 36"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle
                cx="18"
                cy="18"
                r="16"
                fill="none"
                className="stroke-current text-gray-200 dark:text-gray-700"
                strokeWidth="4"
              ></circle>
              <g className="origin-center -rotate-90 transform">
                <circle
                  cx="18"
                  cy="18"
                  r="16"
                  fill="none"
                  className="stroke-current text-bgt-primary dark:text-bgt-primary"
                  strokeWidth="4"
                  strokeDasharray="100"
                  strokeDashoffset={uploadProgress}
                ></circle>
              </g>
            </svg>
          </div>
        )}
      </div>
      <div className="max-w-72">
        <p className="truncate leading-4 font-bold p-0">{filename}</p>
        <small>({getSize()})</small>
      </div>
    </div>
  )
}

interface ResultSummaryProps {
  biomarkers?: BiomarkerMasterMap
  onScrollTo?: (biomarker: string, offset?: number) => void
  onRemove?: (biomarkerId: string, expected?: BiomarkerSource) => void
}

interface ElementProps {
  id: string
  name: string
  value?: string
  refLow?: string
  refHigh?: string
  classList?: string[]
  offset?: number
  abnormal?: boolean
  showActions?: boolean
  expected?: BiomarkerSource
  onRemove?: (biomarkerId: string, expected?: BiomarkerSource) => void
}

export function ResultSummary({
  biomarkers,
  onScrollTo,
  onRemove,
}: ResultSummaryProps) {
  const element = ({
    id,
    name,
    classList,
    offset,
    value,
    abnormal,
    showActions,
    onRemove,
    expected,
  }: ElementProps) => {
    return (
      <div
        className="pb-1 text-md col-span-8"
        key={`biomarker-list-item-${id}`}
      >
        <div
          className={`inline cursor-pointer hover:text-black/50 ${
            classList ? classList?.join(' ') : ''
          } ${
            value === undefined
              ? 'text-gray-dark'
              : `${abnormal ? 'text-blood/80 hover:!text-blood' : ''}`
          }`}
        >
          {showActions && (
            <button
              type="button"
              className="text-xs hover:text-black mr-2"
              onClick={() => {
                console.log('remove this one', id)
                onRemove && onRemove(id, expected)
              }}
            >
              <TrashIcon width={16}></TrashIcon>
            </button>
          )}
          <span onClick={() => onScrollTo && onScrollTo(id, offset)}>
            {name}
          </span>
        </div>
      </div>
    )
  }

  return (
    <Fragment>
      {biomarkers && Object.keys(biomarkers).length > 0 && (
        <div
          className={`side-panel hidden 2xl:z-50 2xl:fixed 2xl:right-0 2xl:top-[4rem] bg-white/60 dark:bg-transparent dark:2xl:bg-dark-gray-light/60  dark:text-white 2xl:w-96 2xl:h-[calc(100dvh-4rem)] 2xl:shadow-lg 2xl:block 2xl:overflow-auto ${
            Object.keys(biomarkers).length > 0 ? '' : '2xl:hidden'
          } relative`}
        >
          <div className="2xl:p-6 2xl:pb-0 2xl:mb-0 mt-4">
            <PageHeading
              title="Biomarker Summary"
              size="lg"
              className="!pb-2 mt-2"
            ></PageHeading>
            <div className="grid grid-cols-8">
              {element({
                id: 'top',
                name: '⬆️',
                classList: [
                  'text-sm fixed top-16 shadow-md bg-white px-4 py-2 rounded-b-lg',
                ],
                offset: 0,
              })}
              <hr className="mb-4"></hr>
              {Object.values(biomarkers)
                .filter((item) => item.expected === BiomarkerSource.Expected)
                .map((item) =>
                  element({
                    id: item.id,
                    name: item.name,
                    value: item.value,
                    abnormal:
                      getResultStatus(
                        item.value?.toString(),
                        item.refIntervalLow?.toString(),
                        item.refIntervalHigh?.toString()
                      ) === ResultStatusTypes.abnormal,
                    showActions: true,
                    onRemove,
                    expected: item.expected,
                  })
                )}
            </div>
          </div>
          <div className="2xl:p-6 2xl:pt-0 2xl:mb-0">
            <Fragment>
              {Object.values(biomarkers).filter(
                (item) => item.expected === BiomarkerSource.ResultImport
              ).length > 0 && (
                <Fragment>
                  <PageHeading
                    title="Additional Biomarkers"
                    size="md"
                    className="!pb-2 mt-2"
                  ></PageHeading>
                  {Object.values(biomarkers)
                    .filter(
                      (item) => item.expected === BiomarkerSource.ResultImport
                    )
                    .map((item) =>
                      element({
                        id: item.id,
                        name: item.name,
                        value: item.value,
                        abnormal:
                          getResultStatus(
                            item.value?.toString(),
                            item.refIntervalLow?.toString(),
                            item.refIntervalHigh?.toString()
                          ) === ResultStatusTypes.abnormal,
                        showActions: true,
                        onRemove,
                        expected: item.expected,
                      })
                    )}
                </Fragment>
              )}
            </Fragment>
          </div>
          <div className="2xl:p-6 2xl:pt-0 2xl:mb-4">
            <Fragment>
              {Object.values(biomarkers).filter(
                (item) => item.expected === BiomarkerSource.Added
              ).length > 0 && (
                <Fragment>
                  <PageHeading
                    title="Added Biomarkers"
                    size="md"
                    className="!pb-2 mt-2"
                  ></PageHeading>
                  {Object.values(biomarkers)
                    .filter((item) => item.expected === BiomarkerSource.Added)
                    .map((item) =>
                      element({
                        id: item.id,
                        name: item.name,
                        value: item.value,
                        abnormal:
                          getResultStatus(
                            item.value?.toString(),
                            item.refIntervalLow?.toString(),
                            item.refIntervalHigh?.toString()
                          ) === ResultStatusTypes.abnormal,
                        showActions: true,
                        onRemove,
                        expected: item.expected,
                      })
                    )}
                </Fragment>
              )}

              {element({
                id: 'bottom',
                name: '⬇️',
                classList: [
                  'text-sm fixed top-16 right-20 shadow-md bg-white px-4 py-2 rounded-b-lg',
                ],
              })}
            </Fragment>
          </div>
        </div>
      )}
    </Fragment>
  )
}
